<div #movilesContainer id="gridMoviles" style="overflow: hidden; height: 100%; overflow-x: hidden;">
    <jqxGrid #gridMovil [theme]="environment.tema" [height]="'100%'" [width]="'100%'" [source]="dataAdapter"
        [appCellClick] [columnsresize]="true" [sortable]="true" [selectionmode]="'checkbox'" [showrowlines]="false"
        [showcolumnlines]="true" [rendertoolbar]="createToolBar" [columns]="columns"
        [altrows]="true" [sortmode]="'many'" [enabletooltips]="true" [toolbarheight]="30" [filterable]="true"
        [showaggregates]="true" [showstatusbar]="true" [statusbarheight]="20" [filterrowheight]="20" [rowsheight]="25"
        [columnsheight]="20" [localization]="langGrid" [filter]="filter" [updatefilterconditions]="updatefilterconditions"
        [enablebrowserselection]="true" [columnmenuopening]="columnmenuopening"(onRowclick)="onRowClick($event)"
        (onBindingcomplete)="onBindingComplete()" (onFilter)="onChangeFilter($event)" (onSort)="onSort($event)">
    </jqxGrid>
</div>
