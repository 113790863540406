import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import {
  CamposCiudadanosVisibleModel,
  LopdCiudadanoModel,
  RolCamposCiudadanoModel
} from 'src/app/services/ciudadanos/models/lopdCiudadano.model';
import { CiudadanosService } from 'src/app/services/ciudadanos/ciudadanos.service';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { HeaderComponent } from '../../header/header.component';
import { jqxTabsComponent } from 'jqwidgets-ng/jqxtabs';
import * as xlsx from 'xlsx';
import { DateUtils } from 'src/app/utils/date-utils';
import { MainComponent } from '../../main/main.component';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { SsoService } from 'src/app/services/sso/sso.service';
import { CustomForms } from '../../forms/custom-forms';
import { RolModel } from 'src/app/services/sso/models/rol.model';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { BehaviorSubject } from 'rxjs';
import { AplicacionModel } from 'src/app/services/sso/models/aplicacion.model';

@Component({
  selector: 'app-lopd-ciudadanos',
  templateUrl: './lopd-ciudadanos.component.html',
  styleUrls: ['./lopd-ciudadanos.component.css']
})
export class LopdCiudadanosComponent extends CustomForms implements OnInit, AfterViewInit, OnDestroy {

  static _this: any;
  @ViewChild('window') window: jqxWindowComponent;
  @ViewChild('myGrid') grid: jqxGridComponent;
  @ViewChild('myGridConf') gridConf: jqxGridComponent;
  @ViewChild('tabs') tabs: jqxTabsComponent;
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('listRoles') listRoles: jqxDropDownListComponent;

  lpodsCiudadanosLength$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  camposCiudadanosVisibleLength$: BehaviorSubject<number> = new BehaviorSubject<number>(0);


  public showLoader = true;
  public tema = environment.tema;
  public langGrid: any;

  lpodsCiudadanos: LopdCiudadanoModel[] = [];
  camposCiudadanosVisible: CamposCiudadanosVisibleModel[] = [];

  mapWidth: number;
  mapHeight: number;
  dataAdapter: any;
  adapterRoles: any;

  idsRolFilter: any[] = [];
  idsRolSelect: any[] = [];
  idsAppSelect: any[] = [];
  roles: RolModel[];
  datosLOPD: RolCamposCiudadanoModel[] = [];
  showCopy: boolean = false;
  esDesactivado: boolean = false;

  createTooltip = (content: string) => {
    return (columnHeaderElement?: any) => {
      const jqElement = columnHeaderElement[0].parentElement;
      const options = {
        content: content,
        theme: environment.tema,
        width: 'auto',
        height: 'auto',
        position: 'mouse',
        autoHide: true,
      };
      jqwidgets.createInstance(jqElement, 'jqxTooltip', options);
    };
  };

  columns: any = [];

  rendexTextGeneric(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {

    if (typeof value === 'string') {
      return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;

    } else if (typeof value === 'number') {
      return `<div style="margin-right: 4px; margin-top: 4px;  text-align: right;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';  this.style.position='fixed'" onmouseout="this.style.backgroundColor=''; this.style.color='';this.style.position=''"> ${value}</span></div>`;
    } else if (value instanceof Date) {

      if (columnfield === 'Fecha') {
        return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';  this.style.position='fixed'" onmouseout="this.style.backgroundColor=''; this.style.color='';this.style.position=''"> ${DateUtils.formatDate(value, true)}</span></div>`;
      } else if (columnfield === 'Hora') {
        return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';  this.style.position='fixed'" onmouseout="this.style.backgroundColor=''; this.style.color='';this.style.position=''"> ${DateUtils.formatTime(value)}</span></div>`;
      }
    }
  }
  dataFilterGrid: any;
  dataFilterGridConf: any;


  columngroupsGeneral: any[] = [];
  columnsConf: any[] = [];
  apps: AplicacionModel[] = [];
  columnGroupsConf: any[] = [];


  groupsrenderer(text?: string, group?: any, expanded?: boolean, data?: any): string {
    if (group === undefined) {
      return;
    }

    let num = data?.subItems.length;
    let name = group;
    let header = '';

    if (name === true) {
      header = '(S)';
    } else if (name === false) {
      header = '(N)';
    } else {
      header = name;
    }

    return '<div style="margin-left: 4px;">' + header + ': ' + num + '</div>';
  }

  dataAdapterConf: any;
  componentRef: any;

  constructor(private ciudadanosService: CiudadanosService, private ssoService: SsoService) {
    super();
    LopdCiudadanosComponent._this = this;
  }

  init(componentRef: any) {
    this.componentRef = componentRef;
  }
  async ngOnInit() {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.loadColumnGeneral();
    this.loadRoles();
  }

  ngAfterViewInit() {
    this.addCustomForm(this.window);
    this.loadWindowTabs();
  }


  async loadColumnGeneral() {
    try {
      // Definir las columnas básicas
      this.columns = [
        {
          text: AppComponent.translate('Tabla'),
          datafield: 'nombreTabla',
          width: '10%',
          editable: false,
          columngroup: this.translate('APLICACIONES'),
          rendered: this.createTooltip(AppComponent.translate('Tabla')),
          cellsrenderer: this.rendexTextGeneric
        },
        {
          text: AppComponent.translate('Campos'),
          datafield: 'nombre',
          width: '10%',
          editable: false,
          columngroup: this.translate('APLICACIONES'),
          rendered: this.createTooltip(AppComponent.translate('Campos')),
          cellsrenderer: this.rendexTextGeneric
        },
        {
          text: AppComponent.translate('D_personal'),
          datafield: 'datoPersonal',
          width: '8%',
          columntype: 'checkbox',
          columngroup: this.translate('Aplicaciones'),
          filtertype: 'list',
          filteritems: ['(S)', '(N)'],
          editable: true,
          rendered: this.createTooltip(AppComponent.translate('Dato_personal'))
        },
      ];

      let apps = await this.ssoService.getApps();
      // let listLopd = await this.ciudadanosService.getLopdsListCiudadanos();
      this.lpodsCiudadanos = await this.ciudadanosService.getLopdsListCiudadanos();

      // Crear dinámicamente las columnas de aplicaciones
      this.columngroupsGeneral = [
        {
          text: this.translate('APLICACIONES'), // Encabezado del grupo que agrupa las columnas
          align: 'center',
          name: this.translate('APLICACIONES')
        }
      ];
      let appsNoInternas = apps.filter(app => !app.Interna);
      let aplicaciones = this.lpodsCiudadanos[0].opcionesAplicaciones;

      aplicaciones = aplicaciones.filter(app => appsNoInternas.some(x => x.Id === app.idAplicacion));

      aplicaciones.forEach((aplicacion, index) => {
        this.columns.push(
          {
            text: this.translate('Oblig'),
            datafield: `${aplicacion.nombreAplicacion}_obligatorio`, // Campo mapeado para obligatorio
            columngroup: aplicacion.nombreAplicacion, // Agrupar por el nombre de la aplicación
            columntype: 'checkbox',
            editable: true,
            rendered: this.createTooltip(this.translate('Obligatorio')),
            aggregates: [{
              'Total': function (aggregatedValue, currentValue: string) {
                return aggregatedValue + 1;
              }
            }],
            aggregatesrenderer: function (aggregates) {
              let renderstring = '';
              if (aggregates['Total'] !== undefined) {
                renderstring = '<div style="margin-left: 4px;">' + aggregates['Total'] + '</div>';
              }
              return renderstring;
            }
          },
          {
            text: this.translate('Busq'),
            datafield: `${aplicacion.nombreAplicacion}_busqueda`, // Campo mapeado para búsqueda
            columngroup: aplicacion.nombreAplicacion, // Agrupar por el nombre de la aplicación
            columntype: 'checkbox',
            editable: true,
            rendered: this.createTooltip(this.translate('Busqueda')),
            aggregates: [{
              'Total': function (aggregatedValue, currentValue: string) {
                return aggregatedValue + 1;
              }
            }],
            aggregatesrenderer: function (aggregates) {
              let renderstring = '';
              if (aggregates['Total'] !== undefined) {
                renderstring = '<div style="margin-left: 4px;">' + aggregates['Total'] + '</div>';
              }
              return renderstring;
            }
          }
        );

        this.columngroupsGeneral.push({
          text: aplicacion.nombreAplicacion,
          align: 'center',
          name: aplicacion.nombreAplicacion,
          rendered: this.createTooltip(aplicacion.nombreAplicacion),
        });
      });
      // Cargar el grid con las nuevas columnas
      this.loadGridGeneral();

    } catch (error) {
      console.error(error);
    }
  }

  loadColumnGeneralFilter(appsFilter) {
    this.dataFilterGrid = this.grid.savestate();

    this.columns = [

      {
        text: AppComponent.translate('Tabla'),
        datafield: 'nombreTabla',
        width: '10%',
        editable: false,
        columngroup: this.translate('APLICACIONES'),
        rendered: this.createTooltip(AppComponent.translate('Tabla')),
        cellsrenderer: this.rendexTextGeneric
      },
      {
        text: AppComponent.translate('Campos'),
        datafield: 'nombre',
        width: '10%',
        editable: false,
        columngroup: this.translate('APLICACIONES'),
        rendered: this.createTooltip(AppComponent.translate('Campos')),
        cellsrenderer: this.rendexTextGeneric
      },
      {
        text: AppComponent.translate('D_personal'),
        datafield: 'datoPersonal',
        width: '8%',
        columntype: 'checkbox',
        columngroup: this.translate('Aplicaciones'),
        filtertype: 'list',
        filteritems: ['(S)', '(N)'],
        editable: true,
        rendered: this.createTooltip(AppComponent.translate('Dato_personal'))
      },
    ];


    let aplicaciones = appsFilter;

    aplicaciones.forEach((aplicacion, index) => {
      this.columns.push(
        {
          text: this.translate('Oblig'),
          datafield: `${aplicacion.nombreAplicacion}_obligatorio`, // Campo mapeado para obligatorio
          columngroup: aplicacion.nombreAplicacion, // Agrupar por el nombre de la aplicación
          columntype: 'checkbox',
          editable: true,
          cellalign: 'center',
          rendered: this.createTooltip(this.translate('Obligatorio')),
          aggregates: [{
            'Total': function (aggregatedValue, currentValue: string) {
              return aggregatedValue + 1;
            }
          }],
          aggregatesrenderer: function (aggregates) {
            let renderstring = '';
            if (aggregates['Total'] !== undefined) {
              renderstring = '<div style="margin-left: 4px;">' + aggregates['Total'] + '</div>';
            }
            return renderstring;
          }
        },
        {
          text: this.translate('Busq'),
          datafield: `${aplicacion.nombreAplicacion}_busqueda`, // Campo mapeado para búsqueda
          columngroup: aplicacion.nombreAplicacion, // Agrupar por el nombre de la aplicación
          columntype: 'checkbox',
          cellalign: 'center',
          editable: true,
          rendered: this.createTooltip(this.translate('Busqueda')),
          aggregates: [{
            'Total': function (aggregatedValue, currentValue: string) {
              return aggregatedValue + 1;
            }
          }],
          aggregatesrenderer: function (aggregates) {
            let renderstring = '';
            if (aggregates['Total'] !== undefined) {
              renderstring = '<div style="margin-left: 4px;">' + aggregates['Total'] + '</div>';
            }
            return renderstring;
          }
        }
      );

      this.columngroupsGeneral.push({
        text: aplicacion.nombreAplicacion,
        align: 'center',
        name: aplicacion.nombreAplicacion,
        rendered: this.createTooltip(aplicacion.nombreAplicacion),
      });
    });

    this.loadGridGeneral();
  }


  loadColumnConfGeneric(appsData) {

    if (this.columnsConf.length > 0 && this.columnGroupsConf.length > 0) {
      this.columnsConf = [];
      this.columnGroupsConf = [];
      this.gridConf.columns(this.columnsConf);
      this.gridConf.columngroups(this.columnGroupsConf);
      this.gridConf.refresh();
      this.gridConf.updatebounddata();
    }

    // Crear arrays vacíos para las columnas y los grupos de columnas
    let columnsConf = [];
    let columnGroups = [];

    // Definir columna básica, por ejemplo "Tabla y Campos"
    columnsConf.push(
      {
        text: AppComponent.translate('Tabla'),
        datafield: 'nombreTabla',
        width: '10%',
        editable: false,
        rendered: this.createTooltip(AppComponent.translate('Tabla')),
        cellsrenderer: this.rendexTextGeneric
      },
      {
        text: AppComponent.translate('Campos'),
        datafield: 'nombre',
        width: '10%',
        editable: false,
        rendered: this.createTooltip(AppComponent.translate('Campos')),
        cellsrenderer: this.rendexTextGeneric
      }
    );

    // Verificamos si hay datos en el array de aplicaciones pasado como argumento
    if (appsData.length > 0) {

      let firstData = appsData[0]; // Obtenemos el primer objeto de aplicaciones

      firstData.aplicaciones.forEach(app => {

        let roles = app.roles; // Obtenemos los roles específicos de esta aplicación

        // Crear un grupo de columnas para cada aplicación (Agrupa los roles)
        columnGroups.push({
          text: app.nombreAplicacion,
          align: 'center',
          name: app.nombreAplicacion,   // Este nombre debe coincidir con el 'columngroup' de las columnas
          rendered: this.createTooltip(app.nombreAplicacion)
        });

        // Iteramos sobre los roles específicos de esta aplicación
        roles.forEach(role => {
          // Crear un grupo para cada rol dentro de la aplicación
          columnGroups.push({
            text: role.nombreRol,
            align: 'center',
            parentgroup: app.nombreAplicacion,
            name: `${app.nombreAplicacion}_${role.nombreRol}`,
            rendered: this.createTooltip(role.nombreRol),
            aggregates: [{
              'Total': function (aggregatedValue, currentValue: string) {
                return aggregatedValue + 1;
              }
            }],
            aggregatesrenderer: function (aggregates) {
              let renderstring = '';
              if (aggregates['Total'] !== undefined) {
                renderstring = '<div style="margin-left: 4px;">' + aggregates['Total'] + '</div>';
              }
              return renderstring;
            }
          });

          // Ahora agregamos una columna para el campo visible dentro del rol
          columnsConf.push({
            text: 'Visible',
            datafield: `visible_${app.idAplicacion}_${role.idRol}`,
            columngroup: `${app.nombreAplicacion}_${role.nombreRol}`,
            width: '5%',
            columntype: 'checkbox',
            editable: true,
            rendered: this.createTooltip('Visible'),
            aggregates: [{
              'Total': function (aggregatedValue, currentValue: string) {
                return aggregatedValue + 1;
              }
            }],
            aggregatesrenderer: function (aggregates) {
              let renderstring = '';
              if (aggregates['Total'] !== undefined) {
                renderstring = '<div style="margin-left: 4px;">' + aggregates['Total'] + '</div>';
              }
              return renderstring;
            }
          });
        });
      });
    }

    // Asignamos los grupos y columnas al grid
    this.columnsConf = columnsConf;
    this.columnGroupsConf = columnGroups;
    this.loadGridConf(appsData); // Cargamos la configuración del grid
  }

  loadColumnConf() {
    if (this.camposCiudadanosVisible.length > 0) {
      // Aquí cargamos todas las aplicaciones y sus roles desde `camposCiudadanosVisible`
      if (this.columnsConf.length > 0 && this.columnGroupsConf.length > 0) {
        return;
      } else {
        this.loader.open();
        let allApps = this.camposCiudadanosVisible;
        this.loadColumnConfGeneric(allApps);
        this.loader.close();
      }
    }
  }


  loadWindowTabs() {
    setTimeout(() => {
      if (this.tabs.selectedItem() === 0) {
        HeaderComponent._this.listRoles.disabled(true);
      } else {
        HeaderComponent._this.listRoles.disabled(false);
      }
    }, 500);
  }

  updateTabGeneral(lopdCiudadanos) {
    this.lpodsCiudadanosLength$.next(lopdCiudadanos.length);
    // Actualiza los títulos de las pestañas
    this.lpodsCiudadanosLength$.subscribe((length) => {
      // Cambiar el título de la primera pestaña
      this.tabs.setTitleAt(0, this.translate('General') + ' (' + length + ')');
    });
  }

  updateTabConf(camposCiudadanosVisible) {
    this.camposCiudadanosVisibleLength$.next(camposCiudadanosVisible.length);
    // Actualiza los títulos de las pestañas
    this.camposCiudadanosVisibleLength$.subscribe((length) => {
      // Cambiar el título de la segunda pestaña
      this.tabs.setTitleAt(1, this.translate('Configuracion_por_rol') + ' (' + length + ')');
    });
  }

  async loadGridGeneral() {
    this.camposCiudadanosVisible = await this.ciudadanosService.getLopdCamposCiudadanos();
    this.updateTabGeneral(this.camposCiudadanosVisible);
    this.updateTabConf(this.camposCiudadanosVisible);

    // Preprocesar los datos antes de cargar en el grid
    this.lpodsCiudadanos.forEach(item => {
      item.opcionesAplicaciones.forEach((aplicacion) => {
        // Añadir campos planos para obligatorio, búsqueda y mantener idAplicacion
        item[`${aplicacion.nombreAplicacion}_obligatorio`] = aplicacion.obligatorio;
        item[`${aplicacion.nombreAplicacion}_busqueda`] = aplicacion.busqueda;
        // Guardar el idAplicacion como parte del objeto (puedes mantenerlo invisible si no lo necesitas en el grid)
        item[`${aplicacion.nombreAplicacion}_idAplicacion`] = aplicacion.idAplicacion;
      });
    });

    // Definir el dataSource con los datafields y el mapeo correspondiente
    let dataSource = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'nombreTabla', type: 'string', map: 'nombreTabla' },
        { name: 'nombre', type: 'string', map: 'nombre' },
        { name: 'empresa', type: 'number', map: 'empresa' },
        { name: 'datoPersonal', type: 'boolean', map: 'datoPersonal' }
      ],
      localdata: this.lpodsCiudadanos,
      sortcolumn: 'nombre',
      sortdirection: 'asc'
    };

    // Agregar dinámicamente los campos para obligatorio, búsqueda y idAplicacion
    this.lpodsCiudadanos[0].opcionesAplicaciones.forEach((aplicacion, index) => {
      dataSource.datafields.push(
        {
          name: `${aplicacion.nombreAplicacion}_obligatorio`, // Campo para obligatorio
          type: 'boolean',
          map: `${aplicacion.nombreAplicacion}_obligatorio` // Mapeo para obligatorio
        },
        {
          name: `${aplicacion.nombreAplicacion}_busqueda`, // Campo para búsqueda
          type: 'boolean',
          map: `${aplicacion.nombreAplicacion}_busqueda` // Mapeo para búsqueda
        },
        {
          name: `${aplicacion.nombreAplicacion}_idAplicacion`, // Campo para idAplicacion
          type: 'number', // Tipo de dato numérico para idAplicacion
          map: `${aplicacion.nombreAplicacion}_idAplicacion` // Mapeo para idAplicacion
        }
      );
    });

    // Configurar el dataAdapter con el dataSource
    this.dataAdapter = new jqx.dataAdapter(dataSource);
    this.grid.source(this.dataAdapter);
    setTimeout(() => {
      this.grid.sortby('nombre', 'asc');
    }, 100);

    this.showLoader = false;
  }

  loadGridConf(appsData) {
    // Inicializamos los datafields básicos (fijos)
    let datafields = [
      { name: 'id', type: 'number' },
      { name: 'nombre', type: 'string' },
      { name: 'nombreTabla', type: 'string', map: 'nombreTabla' },
    ];

    // Verificamos si hay datos disponibles antes de proceder
    if (appsData.length > 0) {
      // Iteramos sobre todas las aplicaciones para generar los datafields dinámicos
      appsData.forEach(data => {
        data.aplicaciones.forEach(app => {
          app.roles.forEach(role => {
            // Agregar un datafield dinámico para la visibilidad de cada rol en cada aplicación
            datafields.push({
              name: `visible_${app.idAplicacion}_${role.idRol}`,  // Nombre único del campo visible
              type: 'boolean'  // Tipo booleano para manejar los checkboxes
            });
          });
        });
      });
    }

    // Mapeamos los datos de ciudadanos al grid
    let gridData = appsData.map(data => {
      let rowData = {
        id: data.id,  // Campo estático de id
        nombre: data.nombre,  // Campo estático de nombre
        nombreTabla: data.nombreTabla  // Campo estático de nombreTabla
      };

      // Iteramos sobre todas las aplicaciones
      data.aplicaciones.forEach(app => {
        // Recorrer los roles de cada aplicación para mapear la visibilidad
        app.roles.forEach(role => {
          // Asignar el campo visible para cada rol
          rowData[`visible_${app.idAplicacion}_${role.idRol}`] = role.visible;
        });
      });

      return rowData;
    });

    // Fuente de datos con los datafields generados dinámicamente
    let sourceConf = {
      datatype: 'json',
      datafields: datafields,
      localdata: gridData,  // Usamos los datos mapeados
      sortcolumn: 'nombre',
      sortdirection: 'asc',
      columns: this.columnsConf,
      columngroups: this.columnGroupsConf
    };

    setTimeout(() => {
      this.gridConf.sortby('nombre', 'asc');
    }, 200);
    // Crear el dataAdapter con los datafields dinámicos
    this.dataAdapterConf = new jqx.dataAdapter(sourceConf);

    // Cerrar el loader después de la carga de datos
    this.loader.close();
  }

  async loadRoles() {
    this.roles = await this.ssoService.getRoles();
    if (this.roles) {
      let dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'Id', type: 'string', map: 'Id' },
          { name: 'Nombre', type: 'string', map: 'Nombre' },
        ],
        localdata: this.roles,
      };

      dataSource.localdata.sort((a, b) => (a.Nombre < b.Nombre ? -1 : 1));
      this.adapterRoles = new jqx.dataAdapter(dataSource,
        {
          beforeLoadComplete: (records: any[]): void => {
            records.sort((a, b) => (a.Nombre < b.Nombre ? -1 : 1));
          },
        }
      );
    }
  }

  filter = (cellValue, rowData, dataField, filterGroup, defaultFilterResult): boolean => {
    if (dataField == 'obligatorio' || dataField == 'datoPersonal') {
      let filterValue = filterGroup.getfilters()[0].value;
      switch (filterValue) {
        case '(S)':
          return cellValue;

        case '(N)':
          return !cellValue;

        default:
          return defaultFilterResult;
      }
    }
  };

  filterConf = (cellValue, rowData, dataField, filterGroup, defaultFilterResult): boolean => {
    if (dataField == 'visible') {
      let filterValue = filterGroup.getfilters()[0].value;
      switch (filterValue) {
        case '(S)':
          return cellValue;

        case '(N)':
          return !cellValue;

        default:
          return defaultFilterResult;
      }
    }
  };

  async onGuardar() {
    this.loader.open();
    if (this.tabs.selectedItem() === 0) {
      this.loader.open();
      if (this.tabs.selectedItem() === 0) {
        let data = this.grid.getrows(); // Obtiene los datos del grid

        // Procesar los datos para reconstruir 'opcionesAplicaciones'
        let dataToSend: any = data.map(row => {
          let opcionesAplicaciones = [];

          // Iterar sobre las claves del objeto (las columnas del grid)
          Object.keys(row).forEach(key => {
            // Verificar si la clave pertenece a 'obligatorio' o 'busqueda'
            if (key.includes('_obligatorio') || key.includes('_busqueda')) {
              // Obtener el nombre de la aplicación eliminando la parte de '_obligatorio' o '_busqueda'
              let nombreAplicacion = key.split('_')[0];

              // Verificar si ya existe la aplicación en 'opcionesAplicaciones'
              let aplicacion = opcionesAplicaciones.find(app => app.nombreAplicacion === nombreAplicacion);

              if (!aplicacion) {
                // Crear un nuevo objeto para la aplicación si no existe
                aplicacion = {
                  nombreAplicacion: nombreAplicacion,
                  idAplicacion: row[`${nombreAplicacion}_idAplicacion`] || 0, // Obtener idAplicacion
                  obligatorio: false,
                  busqueda: false
                };
                opcionesAplicaciones.push(aplicacion);
              }

              // Asignar el valor correspondiente
              if (key.includes('_obligatorio')) {
                aplicacion.obligatorio = row[key];
              } else if (key.includes('_busqueda')) {
                aplicacion.busqueda = row[key];
              }
            }
          });

          // Devolver el objeto correctamente estructurado
          return {
            id: row.id,                     // ID
            empresa: row.empresa || 0,       // Empresa (por defecto 0)
            datoPersonal: row.datoPersonal,  // Dato personal
            opcionesAplicaciones: opcionesAplicaciones // Array de aplicaciones
          };
        });

        let responde = await this.ciudadanosService.postLopdListCiudadanos(dataToSend);
        if (responde) {
          MainComponent.showSuccess('ATENCION', 'Cambios_guardados', 2000);
        } else {
          MainComponent.showError('ATENCION', 'Ha_ocurrido_un_error', 2000);
        }
      }

    } else {
      if (this.gridConf.getrows().length > 0) {
        let dataFromGrid = this.gridConf.getrows();  // Obtenemos los datos del grid
        let appsData = this.camposCiudadanosVisible; // Obtenemos las aplicaciones

        // Obtener los campos originales y aplicar los cambios del grid
        let updatedData = appsData.map(data => {
          // Buscar la fila correspondiente en el grid para este objeto 'data'
          let gridRow = dataFromGrid.find(row => row.id === data.id);

          // Si encontramos la fila correspondiente, aplicamos las actualizaciones
          if (gridRow) {
            // Iteramos sobre las aplicaciones
            data.aplicaciones.forEach(app => {
              app.roles.forEach(role => {
                // Generamos la clave 'visible_X_Y' para acceder a los datos del grid
                let campoVisible = `visible_${app.idAplicacion}_${role.idRol}`;

                // Verificamos si ese campo existe en el objeto gridRow y que no sea undefined o null
                if (gridRow.hasOwnProperty(campoVisible) && gridRow[campoVisible] !== undefined) {
                  // Actualizamos el valor 'visible' usando los datos del grid
                  role.visible = gridRow[campoVisible];
                } else {
                  // Si el campo no existe, asumimos que no hay cambios y mantenemos el valor actual
                  console.warn(`Campo ${campoVisible} no encontrado en gridRow para id: ${data.id}`);
                }
              });
            });
          } else {
            console.warn(`No se encontró una fila correspondiente para el id: ${data.id} en el grid`);
          }

          return data;  // Devolvemos el objeto con los cambios aplicados
        });

        // Log para verificar los datos actualizados
        console.log('Datos actualizados:', updatedData);

        // quito el campo nombreTabla
        updatedData.forEach(x => {
          delete x.nombreTabla;
          delete x.nombre;
          x.aplicaciones.forEach(app => {
            delete app.nombreAplicacion;
            app.roles.forEach(role => {
              delete role.nombreRol;
            });
          });
        });

        console.log(updatedData);


        let response = await this.ciudadanosService.postLopdCamposCiudadanos(updatedData);


        if (response) {
          MainComponent.showSuccess('ATENCION', 'Cambios_guardados', 2000);
        } else {
          MainComponent.showError('ATENCION', 'Ha_ocurrido_un_error', 2000);
        }
      }
    }
    this.loader.close();


  }

  onTabClick(event: any) {
    if (event.args.item === 1) {
      this.showCopy = true;
      HeaderComponent._this.listApps.disabled(false);
      HeaderComponent._this.listRoles.disabled(false);
      this.loadColumnConf();
      this.gridConf.loadstate(this.dataFilterGridConf);

    } else {
      this.showCopy = false;
      HeaderComponent._this.listRoles.disabled(true);
      HeaderComponent._this.listApps.disabled(false);
      this.grid.loadstate(this.dataFilterGrid);
    }
  }

  onSelectRol(event: any) {
    this.idsRolFilter = event;
  }

  onCheckApps(event: any) {
    this.idsAppSelect = event;
    HeaderComponent._this.getrolesWithApp(this.idsAppSelect);
  }

  onUnChecked(event: any) {
    // quito de la lista de campos visibles el campo que se ha deseleccionado
    let dataLOPD = event.args.row;
    this.datosLOPD = this.datosLOPD.filter(x => x.idRol !== dataLOPD.id);
  }


  onChecked(event: any) {

    let dataLOPD = event.args.row;
    if (typeof dataLOPD === 'object' && dataLOPD !== null) {
      // cojo los dos campos que me interesan
      let rowSelect = {
        idRol: dataLOPD.idRol,
        nombreRol: dataLOPD.nombreRol,
        visible: dataLOPD.visible
      };
      this.datosLOPD.push(rowSelect);
    }
  }

  onCheckRol(event: any) {
    this.idsRolSelect = this.listRoles.getCheckedItems().map(x => x.value);
  }

  async onClickCopyConfRol(event) {

    if (this.datosLOPD.length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('Debe_seleccionar_campos'), 2000);
    }

    if (this.idsRolSelect === undefined || this.idsRolSelect.length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('Debe_seleccionar_un_rol'), 2000);
    }

    this.loader.open();
    let dataToSend: any = this.datosLOPD.map(x => {
      return {
        id: x.idRol,
        nombreRol: x.nombreRol,
        visible: x.visible
      };
    });

    let successCount = 0;

    // for (let idRol of this.idsRolSelect) {
    //   try {
    //     let response = await this.ciudadanosService.postCamposVisiblesCiudadanos(dataToSend, idRol);
    //     if (response) {
    //       successCount++;
    //     } else {
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }

    if (successCount > 0) {
      MainComponent.showSuccess('ATENCION', 'Cambios_guardados', 2000);
    }

    this.loader.close();
  }


  onPrint($event: any) {

    if (this.grid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      let gridContent = this.grid.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }

    if (this.gridConf.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      let gridContent = this.gridConf.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets GridConfig</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }

  eventResetFilter() {
    this.loader.open();
    this.header.searchInput['nativeElement'].value = '';
    HeaderComponent._this.listApps.uncheckAll();

    if (this.tabs.selectedItem() === 0) {
      this.grid.clearfilters();
      this.grid.sortby('nombre', 'asc');
      setTimeout(() => {
        this.loadColumnGeneral();
        this.loader.close();
      }, 50);

    } else {
      this.gridConf.clearfilters();
      this.gridConf.sortby('nombre', 'asc');
      HeaderComponent._this.loadListRoles();
      setTimeout(() => {
        this.loadColumnConfGeneric(this.camposCiudadanosVisible);
        this.loader.close();
      }, 50);
    }
  }

  eliminarDuplicados(array: { nombre: string, id: number, obligatorio: boolean, datoPersonal: boolean }[]) {
    const mapa = new Map<string, { nombre: string, id: number, obligatorio: boolean, datoPersonal: boolean }[]>();

    array.forEach(item => {
      const key = item.nombre;
      if (!mapa.has(key)) {
        mapa.set(key, [item]);
      } else {
        const items = mapa.get(key);
        if (items && !items.some(i => i.obligatorio === item.obligatorio && i.datoPersonal === item.datoPersonal)) {
          items.push(item);
        }
      }
    });

    const resultado: { nombre: string, id: number, obligatorio: boolean, datoPersonal: boolean }[] = [];
    mapa.forEach(items => {
      items.forEach(item => resultado.push(item));
    });

    return resultado;
  }


  eliminarDuplicadosPorRol(array: { nombre: string, id: number, visible: boolean }[]) {
    const mapa = new Map<string, { nombre: string, id: number, visible: boolean }[]>();

    array.forEach(item => {
      const key = item.nombre;
      if (!mapa.has(key)) {
        mapa.set(key, [item]);
      } else {
        const items = mapa.get(key);
        if (items && !items.some(i => i.visible === item.visible)) {
          items.push(item);
        }
      }
    });

    const resultado: { nombre: string, id: number, visible: boolean }[] = [];
    mapa.forEach(items => {
      items.forEach(item => resultado.push(item));
    });

    return resultado;
  }

  async onFilter(event: any) {
    this.loader.open();
    if (this.tabs.selectedItem() == 0) {

      if (this.idsAppSelect.length > 0) {

        this.lpodsCiudadanos = await this.ciudadanosService.getLopdsListCiudadanos();

        let aplicaciones = this.lpodsCiudadanos[0].opcionesAplicaciones;

        aplicaciones = aplicaciones.filter(app => this.idsAppSelect.some(x => x.value === app.idAplicacion));

        this.loadColumnGeneralFilter(aplicaciones);

      } else {
      }

    } else {

      if (this.idsRolFilter !== undefined && this.idsRolFilter.length > 0) {

        this.camposCiudadanosVisible = await this.ciudadanosService.getLopdCamposCiudadanos();

        let dataCampos = this.camposCiudadanosVisible.map(campo => {
          // Filtramos las aplicaciones que contienen roles cuyo idRol está en this.idsRoles.value
          let aplicacionesFiltradas = campo.aplicaciones.map(aplicacion => {
            // Filtramos los roles que coincidan con los valores de this.idsRoles
            let rolesFiltrados = aplicacion.roles.filter(rol =>
              this.idsRolFilter.some(idRolFilter => idRolFilter.value === rol.idRol)
            );

            // Devolvemos la aplicación con los roles filtrados
            return { ...aplicacion, roles: rolesFiltrados };
          }).filter(aplicacion => aplicacion.roles.length > 0); // Solo mantenemos aplicaciones con roles filtrados

          // Solo mantenemos el campo si tiene aplicaciones con roles filtrados
          return { ...campo, aplicaciones: aplicacionesFiltradas };
        }).filter(campo => campo.aplicaciones.length > 0);
        this.loadColumnConfGeneric(dataCampos);
      }

    }
    this.loader.close();
  }

  onBuscar() {

    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      // Convertir el valor a mayúsculas y quitar acentos
      filtervalue = this.removeAccents(this.header.searchInput['nativeElement'].value.toUpperCase());
    } else {
      filtervalue = '';
    }

    // Si el valor del filtro es mayor a 2 caracteres, se procede a aplicar el filtro
    if (filtervalue.length > 2) {
      let filtergroup = new jqx.filter();
      let filter_or_operator = 1; // Operador OR para combinar filtros
      let filtercondition1 = 'contains';

      // Crear el filtro con el valor del input
      let filter1 = filtergroup.createfilter('stringfilter', filtervalue, filtercondition1);
      filtergroup.addfilter(filter_or_operator, filter1);

      // Aplicar filtro en la pestaña correspondiente
      if (this.tabs.selectedItem() === 0) {
        this.grid.addfilter('nombre', filtergroup);
        this.grid.applyfilters();
      } else {
        this.gridConf.addfilter('nombre', filtergroup);
        this.gridConf.applyfilters();
      }
    } else {

      if (this.tabs.selectedItem() === 0) {
        // Si el valor del filtro es menor o igual a 2, limpiar los filtros
        this.grid.clearfilters();
      } else {
        this.gridConf.clearfilters();
      }
    }

    // Ordenar la grilla después de aplicar los filtros
    if (this.tabs.selectedItem() === 0) {
      this.dataFilterGrid = this.grid.getfilterinformation();
      // guardo el filtro para recuperarlo despues 
      this.grid.savestate();
      this.grid.sortby('nombre', 'asc');
      this.grid.applyfilters();
      this.updateTabGeneral(this.grid.getrows());
    } else {

      this.dataFilterGridConf = this.gridConf.getfilterinformation();
      // guardo el filtro para recuperarlo despues
      this.gridConf.savestate();
      this.gridConf.sortby('nombre', 'asc');
      this.gridConf.applyfilters();
      this.updateTabConf(this.gridConf.getrows());
    }
  }

  // Función para eliminar los acentos
  removeAccents(value: string): string {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  onExportar() {
    const wb = xlsx.utils.book_new(); // Crear libro de trabajo

    if (this.grid.getrows().length > 0) {
      const json = JSON.parse(JSON.stringify(this.grid.getrows()));

      let campos = this.translate('Campos');
      let obligatorio = this.translate('Obligatorio');
      let datoPersonal = this.translate('Dato_personal');

      json.forEach(element => {
        delete element.id;
        delete element.uid;
        delete element.uniqueid;
        delete element.visibleindex;
        delete element.boundindex;
        delete element[''];

        element[campos] = element.nombre;
        element[obligatorio] = element.obligatorio;
        element[datoPersonal] = element.datoPersonal;

        delete element.nombre;
        delete element.obligatorio;
        delete element.datoPersonal;

        element[obligatorio] = element[obligatorio] ? 'Si' : 'No';
        element[datoPersonal] = element[datoPersonal] ? 'Si' : 'No';
      });

      const ws1 = xlsx.utils.json_to_sheet(json);
      this.generateAutofilterHeader(ws1);
      xlsx.utils.book_append_sheet(wb, ws1, this.translate('General')); // Añadir hoja al libro
    }

    const jsonConf = JSON.parse(JSON.stringify(this.gridConf.getrows()));

    let roles_app = this.translate('Roles_app');
    let campo = this.translate('Campo');

    jsonConf.forEach(element => {
      delete element.id;
      delete element.uid;
      delete element.uniqueid;
      delete element.visibleindex;
      delete element.boundindex;
      delete element[''];

      element[roles_app] = element.nombre;
      element[campo] = element.obligatorio;

      delete element.nombre;
      delete element.obligatorio;

      element[campo] = element[campo] ? 'Si' : 'No';
    });

    const ws2 = xlsx.utils.json_to_sheet(jsonConf);
    this.generateAutofilterHeader(ws2);
    xlsx.utils.book_append_sheet(wb, ws2, this.translate('Configuracion_por_rol')); // Añadir hoja al libro
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Lopd_ciudadanos') + '.xlsx');
  }

  generateAutofilterHeader(sheet) {
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }


  selectionRolRenderer = (): string => {
    if (this.listRoles) {
      let selectedItems = this.listRoles.getCheckedItems();
      if (selectedItems !== undefined && selectedItems.length > 0) {
        let itemValue = selectedItems.map(x => x.label).join(', ');
        return `<div style="position: relative; margin-left: 3px; margin-top: 5px;">${itemValue}</div>`;
      } else {
        return '<div style="position: relative; margin-left: 5px; margin-top: 5px;"> Selecciona Rol  </div>';
      }
    }
  };

  ngOnDestroy(): void {
    this.window.destroy();
    this.componentRef = null;
    LopdCiudadanosComponent._this = null;
  }

  onClose() {
    this.componentRef.destroy();
    LopdCiudadanosComponent._this = null;
  }

}
