<div class="loader-container" *ngIf="showLoader">
  <div class=" loader"></div>
</div>

<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>

<jqxWindow #window [jqxWindowAutoResize] [theme]="tema" [position]="getFormPos(window, 24)" [zIndex]="900"
  [width]="mapWidth" [height]="mapHeight - 24" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true"
  [resizable]="true" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    LOPD
  </div>

  <div class="form" style="float:left; overflow: hidden;">
    <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [activeAppFilter]="true"
      [lopdCiudadanos]="true" (eventFilter)="onFilter($event)" [filtro]="true" [resetFiltro]="true"
      (eventImprimir)="onPrint($event)" (eventExportar)="onExportar()" (eventBuscador)="onBuscar()"
      (eventResetFilter)="eventResetFilter()" (eventCheckRol)="onSelectRol($event)"
      (eventCheckApps)="onCheckApps($event)">
    </app-header>

    <jqxTabs #tabs [theme]="tema" [width]="'100%'" [height]="'calc(100% - 66px)'" (onTabclick)="onTabClick($event)"
      [animationType]="'none'">
      <ul>
        <li>1</li>
        <li>2</li>
      </ul>
      <div>
        <jqxGrid #myGrid [theme]="tema" [width]="'99%'" [height]="'calc(100% - 5px)'" [source]="dataAdapter"
          [columnsresize]="true" [localization]="langGrid" [filterable]="true" [columns]="columns" [filter]="filter"
          [showrowlines]="false" [appCellClick] [enablebrowserselection]="true" [showcolumnlines]="true"
          [columngroups]="columngroupsGeneral" [pageable]="false" [sortable]="true" [altrows]="true"
          [showgroupsheader]="false" [groupsrenderer]="groupsrenderer" [showstatusbar]="true" [statusbarheight]="20"
          [editable]="true" [showaggregates]="true" [rowsheight]="25" [columnsheight]="25" [groupable]="true">
        </jqxGrid>
      </div>
      <div>
        <!-- <div style="margin-bottom: 2px;">
          <jqxDropDownList #listRoles [theme]="tema" [width]="200" [height]="25" [searchMode]="'containsignorecase'"
            [filterPlaceHolder]="'Buscar...'" [displayMember]="'Nombre'" [filterable]="true" [source]="adapterRoles"
            [valueMember]="'Id'" [dropDownHeight]="200" [checkboxes]="true" [selectionRenderer]="selectionRolRenderer"
            [disabled]="false" (onCheckChange)="onCheckRol($event)">
          </jqxDropDownList>
        </div> -->

        <jqxGrid #myGridConf [theme]="tema" [width]="'99%'" [height]="'calc(100% - 5px)'" [source]="dataAdapterConf"
          [columnsresize]="true" [localization]="langGrid" [filterable]="true" [columns]="columnsConf"
          [filter]="filterConf" [showrowlines]="false" [appCellClick] [columngroups]="columnGroupsConf"
          [groupsrenderer]="groupsrenderer" [enablebrowserselection]="true" [showcolumnlines]="true" [pageable]="false"
          [sortable]="true" [altrows]="true" [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true"
          [selectionmode]="'checkbox'" [rowsheight]="25" [columnsheight]="25" [editable]="true" [groupable]="true"
          (onRowselect)="onChecked($event)" (onRowunselect)="onUnChecked($event)">
        </jqxGrid>

      </div>
    </jqxTabs>

    <div style="display: flex;">
      <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
        [textPosition]='"left"' [imgPosition]="'left'" [imgSrc]='"/assets/images/save.png"'
        [value]="translate('Guardar')" (onClick)="onGuardar()" [disabled]="esDesactivado">
      </jqxButton>

      <jqxButton style="float: left; margin-left: 3px" [width]="100" [height]="26" [hidden]="!showCopy"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/copy.png'"
        [imgPosition]="'left'" [value]="'Copiar Rol'" (onClick)="onClickCopyConfRol($event)">
      </jqxButton>

      <jqxButton style="float: left; margin-left: 3px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/cancel.png'"
        [imgPosition]="'left'" [value]="translate('CANCELAR')" (onClick)="componentRef.destroy()">
      </jqxButton>
    </div>
  </div>
</jqxWindow>