<jqxRibbon style="overflow: hidden" [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [position]="'top'">
  <ul style="width: 100%; text-align: left">
    <li style="height: 26px; vertical-align: middle">
      {{ translate("Volumetrico") }}
    </li>
    <li style="height: 26px; vertical-align: middle">
      {{ translate("Cerradura") }}
    </li>
  </ul>
  <div>
    <div class="row tab"
      style="background-color: transparent; padding: 0%; overflow-y: hidden !important; margin-top: 40px;">
      <div id="fieldPerifericoLectura">
        <fieldset>
          <legend>{{ translate('Ultima_lectura') }}</legend>

          <div class="row">
            <span style="width: 100%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Fecha_lectura") }}
              </div>
              <jqxInput [value]="formatDateTime(elemEdit?.UltFechaVolum)" [width]="'90%'" [rtl]="true"
                [disabled]="true">
              </jqxInput>
            </span>

            <span>
              <div style="margin-top: 5px; clear: both">
                {{ translate("Hora") + ' ' + translate("Lectura") }}
              </div>
              <jqxInput [value]="formatHour(elemEdit?.UltFechaVolum)" [width]="'90%'" [rtl]="true" [disabled]="true">
              </jqxInput>
            </span>
          </div>

          <div class="row">
            <span style="width: 50%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Nivel_llenado_porcentaje") }}
              </div>
              <app-input-number [value]="elemEdit?.UltPctjeVolum" [render]="renderer()" [width]="'40%'" [decimal]="0"
                [disabled]="true">
              </app-input-number>
            </span>

            <span style="width: 50%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Lectura") }}
              </div>
              <app-input-number [value]="elemEdit?.UltLecturaVolum" [render]="renderer()" [width]="'40%'" [decimal]="0"
                [disabled]="true">
              </app-input-number>
            </span>
          </div>
        </fieldset>
      </div>

      <div id="fieldPerifericoVolumetrico">
        <fieldset>
          <legend>{{ translate('Volumetrico') }}</legend>
          <div class="row">
            <span style="width: 100%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Fecha_instalacion") }}
              </div>
              <jqxDateTimeInput [value]="elemEdit?.FechaInstalacionVolum" [width]="'40%'" [rtl]="true"
                [disabled]="true">
              </jqxDateTimeInput>
            </span>
          </div>

          <div class="row">
            <span style="width: 50%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate('Altura_sensor') }} (cm)
              </div>
              <app-input-number [value]="elemEdit?.AlturaSensor" (onChange)="elemEdit.AlturaSensor = $event"
                [render]="renderer()" [width]="'100%'" [decimal]="2" [renderizar]="false">
              </app-input-number>
            </span>

            <span style="width: 50%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate('Altura_muerta') }} (cm)
              </div>
              <app-input-number [value]="elemEdit?.AlturaMuerta" (onChange)="elemEdit.AlturaMuerta = $event"
                [render]="renderer()" [width]="'100%'" [decimal]="2" [renderizar]="false">
              </app-input-number>
            </span>
          </div>

          <div class="row">
            <span style="width: 50%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate('Grados_sensor') }} (º)
              </div>
              <app-input-number [value]="elemEdit?.GradosSensor" (onChange)="elemEdit.GradosSensor = $event"
                [render]="renderer()" [width]="'100%'" [decimal]="2" [renderizar]="false">
              </app-input-number>
            </span>

            <span style="width: 50%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Sim") }}
              </div>
              <app-input-number [value]="elemEdit?.SimVolum" (onChange)="elemEdit.SimVolum = $event"
                [render]="renderer()" [width]="'100%'" [decimal]="2" [renderizar]="false">
              </app-input-number>
            </span>
          </div>

          <div class="row">
            <span style="width: 100%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Imei") }}
              </div>
              <jqxDropDownList #dropImeiVolumetrico style="float: left" [source]="sourceImeiVolumetrico"
                [filterable]="true" [filterPlaceHolder]="translate('Buscar')" [width]="'100%'"
                [searchMode]="'containsignorecase'" [theme]="environment.tema"
                (onChange)="changeImeiVolumetrico($event)">
              </jqxDropDownList>
            </span>
          </div>

          <div class="row">
            <span style="width: 50%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Frecuencia") }}
              </div>
              <jqxInput [value]="elemEdit?.Frecuencia?.Nombre"
                (onChange)="elemEdit.Frecuencia != null ? elemEdit.Frecuencia.Nombre = $event : null" [width]="'100%'"
                [rtl]="true">
              </jqxInput>
            </span>

            <span style="width: 50%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Alarma") }}
              </div>
              <jqxInput [value]="elemEdit?.Alarma?.Nombre"
                (onChange)="elemEdit.Alarma != null ? elemEdit.Alarma.Nombre = $event : null" [width]="'100%'"
                [rtl]="true">
              </jqxInput>
            </span>
          </div>
        </fieldset>
      </div>

      <div id="fieldPerifericoGrafica">
        <fieldset>
          <legend>{{ translate('Grafico') }}</legend>

          <div class="row">
            <span style="width: 100%; display: flex; margin-bottom: 4px; justify-content: flex-start;">
              <app-periodo-select #periodoSelect [noMes]="false" [noTrimestre]="true" [noSemestre]="true"
                [noAnno]="true" [noLibre]="true" [height]="24"></app-periodo-select>
              <jqxButton style="float:left; margin-left: 4px;" [width]='80' [height]='26' [value]="translate('Aplicar')"
                (onClick)="getLecturas()">
              </jqxButton>
            </span>
          </div>

          <div class="row" style="z-index: 101;">
            <jqxChart *ngIf="seriesGroups != null" [width]="750" [height]="350" [seriesGroups]="seriesGroups"
              [xAxis]="xAxis" [title]="'Temperatura y llenado'" [description]="''">
            </jqxChart>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="row tab"
      style="background-color: transparent; padding: 0%; overflow-y: hidden !important; margin-top: 40px;">
      <div #fieldPerifericoCerradura id="fieldPerifericoCerradura">
        <fieldset>
          <legend>EcoLock</legend>

          <div class="row" style="justify-content: flex-start !important;">
            <span style="width: 25%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Fecha_instalacion") }}
              </div>
              <jqxDateTimeInput [value]="formatDate(elemEdit?.cerradura?.fechaCreacion)" [width]="'100%'" [rtl]="true"
                [disabled]="true">
              </jqxDateTimeInput>
            </span>

            <div #spanCerradura style="width: 100%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Ns_movisat") }}
              </div>
              <!-- Make jqxDropDownButton smaller -->
              <jqxDropDownButton #dropCerraduras style="float: left" [width]="170" [theme]="environment.tema"
                (focusout)="closeDropCerraduras($event)" (onClose)="onCloseDropCerraduras($event)"
                (onOpen)="onOpenDropCerraduras($event)">
                <!-- Make jqxGrid larger -->
                <div [hidden]="gridDropCerraduras">
                  <jqxGrid id="gridDdCerraduras" #gridDropDownCerraduras [theme]="environment.tema" [width]="410"
                    [appCellClick] [height]="260" [source]="dataAdapterCerraduras" [columnsresize]="true"
                    [selectionmode]="'singlerow'" [columns]="columnsCerradura" [sortable]="true" [altrows]="true"
                    [showrowlines]="true" [enablebrowserselection]="true" [rowsheight]="20" [columnsheight]="20"
                    [localization]="langGrid" [filterable]="true" [columnmenuopening]="columnmenuopening"
                    (onRowclick)="onRowClickCerradura($event)" (focusout)="closeDropCerraduras($event)">
                  </jqxGrid>
                </div>
              </jqxDropDownButton>
            </div>
          </div>

          <div class="row">
            <span style="width: 33%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Ns_fabricante") }}
              </div>
              <jqxInput [value]="elemEdit?.cerradura?.nsFabricante" [width]="'100%'" [rtl]="true" [disabled]="true">
              </jqxInput>
            </span>

            <span style="width: 33%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Imei") }}
              </div>
              <jqxInput [value]="elemEdit?.cerradura?.imei" [width]="'100%'" [rtl]="true" [disabled]="true">
              </jqxInput>
            </span>

            <span style="width: 33%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Sim") }}
              </div>
              <jqxInput [value]="elemEdit?.cerradura?.sim" [width]="'100%'" [rtl]="true" [disabled]="true">
              </jqxInput>
            </span>
          </div>

          <div class="row">
            <span style="width: 100%;">
              <jqxButton style="float: right" [width]="100" [height]="26" [textPosition]="'left'"
                [value]="translate('Desasociar')" (onClick)="disassociateCerraduraFromElemento()"
                [disabled]="!elemEdit?.cerradura">
              </jqxButton>
            </span>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</jqxRibbon>