<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #form [jqxWindowAutoResize] [position]="getFormPos(form, 24)" [theme]="theme" [zIndex]="1000"
  [width]="mapWidth" [height]="mapHeight" [autoOpen]="true" [showCloseButton]="false">
  <div hidden="true"></div>
  <div class="form">
    <jqxTabs #myTabs [width]="'100%'" [height]="'100%'" [theme]="theme" [scrollable]="false"
      (onTabclick)="onTabClick($event)" style="position: absolute;">
      <ul>
        <li>I</li>
        <li>II</li>
      </ul>
      <div style="width: calc(100% - 5px); height: calc(100% - 8px); overflow: hidden;">
        <div>
          <app-header #header [exportar]="true" [imprimir]="true" [periodo]="true" [buscador]="true"
            [checkMetros]="true" [filtro]="true" [resetFiltro]="true" [noLibre]="true" (eventExportar)="onExportar()"
            (eventImprimir)="onPrint()" (eventBuscador)="onBuscar()" (eventCheckMasMetros)="checkMasXmetros()"
            (eventChangeMasXMetros)="changeMasXmetros($event)" (eventFilter)="onAceptar()"
            (eventResetFilter)="onResetFilter()">
          </app-header>
        </div>
        <jqxGrid #myGrid [theme]="theme" [width]="'calc(100% - 3px)'" [height]="'calc(100% - 40px)'"
          [source]="dataAdapter" [appCellClick] [enablebrowserselection]="true" [columnsresize]="true" [sortable]="true"
          [showrowlines]="false" [showcolumnlines]="true" [columns]="columns" [columngroups]="columngroups"
          [altrows]="true" [filterable]="true" [showstatusbar]="true" [statusbarheight]="20" [rowdetails]="true"
          [showaggregates]="true" [rowsheight]="20" [columnsheight]="20" [localization]="langGrid" [groupable]="true"
          [rowdetailstemplate]="rowdetailstemplate" [filter]="filter" [initrowdetails]="initRowDetails"
          [updatefilterconditions]="updatefilterconditions" (onRowexpand)="rowExpand($event)"
          (onRowcollapse)="rowCollapse($event)" (onRowdoubleclick)="onRowdoubleclick($event)" (onSort)="onSort($event)"
          [columnmenuopening]='columnmenuopening'>
        </jqxGrid>
      </div>
      <div style="width: calc(100% - 5px); height: calc(100% - 8px)">
        <movisat-map #cartoMap [provider]="mapProvider" (mapReady)="onMapReady($event)" [cartoType]="cartoType"
          [lang]="lang" [texts]="langService.getCurrentTexts()" [searchCountry]="searchCountry"
          [searchBounds]="searchBounds" (boundsChange)="onBoundsChange($event)" [noSearch]="true" [zoom]="zoom"
          [center]="center">
        </movisat-map>
      </div>
    </jqxTabs>
  </div>
</jqxWindow>